// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemeService } from '@app/services/theme/theme.service';
import { environment } from '@env/environment';
import { KeycloakService } from 'keycloak-angular';
import { AppConfig } from '@app/app.config';
import { IdentityProviders } from '@env/environment.model';
import { ParametersService } from '@app/services/parameters/parameters.service';
import { filter, combineLatest, forkJoin } from 'rxjs';
import { isNonNull } from '@app/guards/business-window.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginFormGroup: FormGroup = this._formBuilder.group({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  public isFormSubmitted: boolean;
  public errorMessage: string;
  public authenticationFlow: 'basic' | 'oauth';
  private _theme: string = environment.theme;

  constructor(
    public themeService: ThemeService,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _keycloakService: KeycloakService,
    private _configService: AppConfig,
    private _parametersService: ParametersService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const identityProvider = this._configService.getConfig('identityProvider');
    this.authenticationFlow = identityProvider?.name === IdentityProviders.KEYCLOAK ? 'oauth' : 'basic';
    if (this.authenticationFlow === 'oauth') {
      return this._followOpenAuthenticationFlow();
    }
    this.themeService.setTheme(this._theme);
    this.loginFormGroup.reset();
  }

  private async _followOpenAuthenticationFlow(): Promise<void> {
    if (await this._keycloakService.isLoggedIn()) {
      forkJoin([
        this._parametersService.getBusinessWindows(),
        this._authenticationService.loadUserProfile()
      ]).subscribe({
        next: () => this.redirectToDashboard(),
        error: (): string =>
          (this.errorMessage =
            'Successfully logged-in but failed to load the user profile or the business windows. <br/>' +
            'Please refresh the page in a moment or contact an administrator.')
      });
      return;
    }

    this._keycloakService.login().catch((error): void => {
      console.error('Failed to redirect to keycloak login page', error);
    });
  }

  public basicLogin(): void {
    this.loginFormGroup.markAsPending();
    this._authenticationService.login(this.loginFormGroup.value.email, this.loginFormGroup.value.password).subscribe({
      next: (): void => {
        this.redirectToDashboard();
      },
      error: (error): void => {
        this.errorMessage = 'Invalid authentication ID or password';
        throw error;
      }
    });
  }

  public redirectToDashboard(): void {
    this._router.navigate(['./app']).catch((error): void => {
      console.error('Failed to navigate to dashboard page ("app")', error);
    });
  }
}
