import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, filter, forkJoin, Observable } from 'rxjs';
import { ParametersService } from "@app/services/parameters/parameters.service";
import { Tools } from "@app/services/common/tools";
import { BusinessWindow, BusinessWindowScope } from "@models/business-date/business-date.model";
import { CurrentWindowPipe } from "@app/components/shared/pipes/current-window.pipe";
import { User } from "@models/access/user.model";
import { AuthenticationService } from "@app/services/authentication/authentication.service";
import { map } from "rxjs/operators";
import { BusinessWindowHelper } from '@models/business-date/business-window.helper';
import { AppConfig } from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class BusinessWindowGuard implements CanActivate {
  constructor(
    private _parametersService: ParametersService,
    private _router: Router,
    private _authService: AuthenticationService,
    private _configService: AppConfig,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._configService.getConfig("disableBusinessWindows")) {
      return true;
    }
    return combineLatest([
      this._authService.onAuthChange,
      this._parametersService.getBusinessWindows()
    ]).pipe(
      filter(isNonNull),
      map(([currentUser, businessWindows]: [User, BusinessWindow[]]) => {
        if (currentUser.isOperator) {
          return true;
        }

        const currentBusinessWindow: BusinessWindowScope = BusinessWindowHelper.computeCurrentWindow(businessWindows).name;
        if (currentBusinessWindow === BusinessWindowScope.OPEN_FOR_ALL) {
          return true;
        }

        if (currentBusinessWindow === BusinessWindowScope.START_OF_DAY ||
          currentBusinessWindow === BusinessWindowScope.END_OF_DAY) {
          return currentUser.isExternalEntity ? this.redirectToPlatformClosedPage() : true;
        }
        return this.redirectToPlatformClosedPage();
      }));
  }

  public redirectToPlatformClosedPage(): UrlTree {
    return this._router.createUrlTree(['platform-closed']);
  }
}

export function isNonNull([currentUser, businessWindows]: [User | null, BusinessWindow[] | null]) {
  return currentUser != null && businessWindows != null;
}
