import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: 'formly-section',
  templateUrl: './section-wrapper.component.html',
  styleUrls: ['./section-wrapper.component.scss']
})
export class SectionWrapperComponent extends FieldWrapper {
}
